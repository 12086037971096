import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useInView } from 'react-intersection-observer';
import axios from 'axios';
import QuestInList from './QuestInList';
import { fetchQuests, likeQuest, setActiveTab } from './QuestsActions';

const QuestsList = ({ 
  fetchQuests, 
  likeQuest,
  setActiveTab,
  quests,
  loading, 
  error, 
  isAuthenticated,
  activeTab
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [loadingError, setLoadingError] = useState(null);
  const [needsRefresh, setNeedsRefresh] = useState(false);
  
  const finishedQuests = useSelector(state => state.quests.finishedQuests);
  const [ref, inView] = useInView({
    threshold: 0,
  });

  // Handle auth changes and initial mount
  useEffect(() => {
    // Reset state when auth changes
    setPage(1);
    setHasMore(true);
    setLoadingError(null);
    
    if (activeTab === "finished") {
      // Clear finished quests
      dispatch({ type: 'ADD_FINISHED_QUESTS', payload: [] });
      // Set flag to trigger refresh
      setNeedsRefresh(true);
    }
  }, [isAuthenticated]); // Only depend on auth changes

  // Update useEffect for tab changes
  useEffect(() => {
    setPage(1);
    setHasMore(true);
    setLoadingError(null);

    if (activeTab === "active") {
      fetchQuests(true);
    } else {
      // Clear and refetch finished quests
      dispatch({ type: 'ADD_FINISHED_QUESTS', payload: [] });
      if (isAuthenticated) {
        fetchMoreFinishedQuests();
      }
    }
  }, [activeTab]);

  // Watch for infinite scroll
  useEffect(() => {
    if (inView && activeTab === "finished" && !isLoadingMore && hasMore) {
      fetchMoreFinishedQuests();
    }
  }, [inView, activeTab, isLoadingMore, hasMore]);

  const handleLikeClick = async (questId) => {
    if (!isAuthenticated) {
      navigate('/login');
      return;
    }
  
    try {
      await likeQuest(questId);
    } catch (error) {
      console.error('Error liking quest:', error);
    }
  };

  const fetchMoreFinishedQuests = useCallback(async () => {
    if (isLoadingMore || !hasMore) return;
  
    setIsLoadingMore(true);
    setLoadingError(null);
    
    try {
      const timestamp = new Date().getTime();
      const response = await axios.get(
        `/api/v1/quests/?page=${page}&active=false&inactive_only=true&_=${timestamp}`
      );
      const newQuests = response.data.results || [];
      
      if (newQuests.length === 0) {
        setHasMore(false);
      } else {
        dispatch({ 
          type: 'ADD_FINISHED_QUESTS', 
          payload: newQuests
        });
        setHasMore(!!response.data.next);
        setPage(prev => prev + 1);
      }
    } catch (error) {
      // Handle 404 as "no more pages" instead of an error
      if (error.response?.status === 404) {
        setHasMore(false);
        setLoadingError(null);
      } else {
        console.error('Error fetching finished quests:', error);
        setLoadingError(error.message);
      }
    } finally {
      setIsLoadingMore(false);
      setNeedsRefresh(false);
    }
  }, [page, isLoadingMore, hasMore, dispatch]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    if (tab === "finished" && finishedQuests.length === 0) {
      setPage(1);
      setHasMore(true);
      setLoadingError(null);
      fetchMoreFinishedQuests();
    }
  };

  useEffect(() => {
    if (inView && activeTab === "finished" && !isLoadingMore && hasMore) {
      fetchMoreFinishedQuests();
    }
  }, [inView, activeTab, isLoadingMore, hasMore, fetchMoreFinishedQuests]);

  useEffect(() => {
    if (needsRefresh && activeTab === "finished" && isAuthenticated) {
      fetchMoreFinishedQuests();
    }
  }, [needsRefresh, activeTab, isAuthenticated, fetchMoreFinishedQuests]);

  if (loading && !finishedQuests.length) {
    return (
      <div className="flex justify-center items-center min-h-[200px]">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center text-red-500 p-4 rounded-lg bg-red-100">
        Error loading quests: {error}
      </div>
    );
  }

  const questsToDisplay = quests?.results || [];

  const renderQuests = (questsList) => {
    return questsList.map(quest => {
      // Ensure quest has all required props before rendering
      if (!quest || !quest.id) {
        console.warn('Invalid quest data:', quest);
        return null;
      }
      
      return (
        <QuestInList 
          key={quest.id} 
          quest={quest} 
          isAuthenticated={isAuthenticated}
          onLikeClick={handleLikeClick}
        />
      );
    }).filter(Boolean); // Remove null entries
  };

  return (
      <div className="w-[88vw] sm:w-full max-w-4xl mx-auto px-0 sm:px-2">
        <h1 className="text-4xl font-bold text-center bg-gradient-to-r from-green-500 to-blue-500 bg-clip-text text-transparent transition-opacity">
          Quests
        </h1>

        <div className="relative flex space-x-1 mb-8 p-1 rounded-lg mx-auto w-[70vw] sm:w-full sm:max-w-xl">
          {/* Gradient border background */}
          <div className="absolute inset-0 rounded-lg bg-gradient-to-r from-purple-600/20 via-blue-600/20 to-purple-600/20" />
          
          {/* Content */}
          <div className="relative flex w-full bg-gray-800/50 rounded-lg backdrop-blur-sm">
            <button
              onClick={() => handleTabChange("active")}
              className={`flex-1 py-2 sm:py-3 px-4 sm:px-6 rounded-md text-sm sm:text-md font-medium transition-all duration-300 ${
                activeTab === "active"
                  ? "bg-gradient-to-r from-purple-700 to-blue-700 text-white shadow-[0_0_10px_rgba(168,85,247,0.3)]"
                  : "text-gray-400 hover:text-white hover:bg-gray-700/50"
              }`}
            >
              Active Quests
            </button>
            <button
              onClick={() => handleTabChange("finished")}
              className={`flex-1 py-2 sm:py-3 px-4 sm:px-6 rounded-md text-sm sm:text-md font-medium transition-all duration-300 ${
                activeTab === "finished"
                  ? "bg-gradient-to-r from-blue-700 to-purple-700 text-white shadow-[0_0_10px_rgba(168,85,247,0.3)]"
                  : "text-gray-400 hover:text-white hover:bg-gray-700/50"
              }`}
            >
              Finished Quests
            </button>
          </div>
        </div>

      <div className="space-y-4 md:space-y-6">
        {activeTab === "active" ? (
          questsToDisplay.length === 0 ? (
            <div className="text-center text-gray-400 py-8">
              No active quests available at the moment
            </div>
          ) : (
            renderQuests(questsToDisplay)
          )
        ) : (
          <>
            {renderQuests(finishedQuests)}
            
            {loadingError && (
              <div className="text-center text-red-500 p-4">
                Error loading finished quests: {loadingError}
              </div>
            )}
            
            {hasMore && !loadingError && (
              <div ref={ref} className="text-center py-4">
                {isLoadingMore ? (
                  <div className="inline-block animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
                ) : (
                  <div className="text-gray-400">Scroll for more</div>
                )}
              </div>
            )}
            
            {!hasMore && finishedQuests.length > 0 && !loadingError && (
              <div className="text-center py-4 text-gray-400">
                No more finished quests to load
              </div>
            )}
            
            {finishedQuests.length === 0 && !isLoadingMore && !loadingError && (
              <div className="text-center text-gray-400 py-8">
                No finished quests available
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  quests: state.quests.quests,
  loading: state.quests.loading,
  error: state.quests.error,
  isAuthenticated: state.auth.isAuthenticated,
  activeTab: state.quests.activeTab
});

export default connect(
  mapStateToProps, 
  { fetchQuests, likeQuest, setActiveTab }
)(QuestsList);