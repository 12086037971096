import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from './login/LoginActions';
import { LogOut, Settings } from 'lucide-react';

const NavBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, isAuthenticated } = useSelector(state => state.auth);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const dropdownRef = useRef(null);
  const timeoutRef = useRef(null);
  const location = useLocation();

  const isPathActive = (path) => {
    return location.pathname === path ||
           (path !== '/' && location.pathname.startsWith(path));
  };

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.matchMedia('(max-width: 768px)').matches);
    };

    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);
    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  const handleLogout = () => {
    dispatch(logout());
    setIsDropdownOpen(false);
    navigate('/');
  };

  const handleMouseEnter = () => {
    if (!isMobile) {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      setIsDropdownOpen(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isMobile) {
      timeoutRef.current = setTimeout(() => {
        setIsDropdownOpen(false);
      }, 150);
    }
  };

  const handleClick = () => {
    if (isMobile) {
      setIsDropdownOpen(!isDropdownOpen);
    } else {
      navigate(`/u/${user.username}`);
    }
  };

  const NavIcon = ({ src, alt, text, to }) => {

    const location = useLocation();
    const navigate = useNavigate();
    
    const isPathActive = (path) => {
      return location.pathname === path || 
        (path !== '/' && location.pathname.startsWith(path));
    };

    const isActive = Array.isArray(to)
      ? to.some(path => isPathActive(path))
      : isPathActive(to);

    const linkTo = Array.isArray(to) ? to[0] : to;
    
    const handleNavClick = (e) => {
      if (isActive) {
        e.preventDefault();
        // Check if we're on a specific quest page and the Quests icon was clicked
        if (location.pathname.startsWith('/quest/') && Array.isArray(to) && to.includes('/quests')) {
          navigate('/quests');
        } else {
          window.location.reload();
        }
      }
    };
    
    return (
      <Link 
        to={linkTo}
        onClick={handleNavClick}
        className={`flex flex-col items-center justify-center text-center group 
                   ${isMobile ? 'px-1 min-w-[45px]' : 'px-2 min-w-[70px]'}`}
      >
        <div className={`relative ${isMobile ? 'w-6 h-6' : 'w-8 h-8'} mb-0.5`}>
          <img 
            src={src} 
            alt={alt} 
            className={`w-full h-full transition-all duration-300 filter group-hover:scale-110 ${
              isActive 
                ? 'brightness-100 invert-0 opacity-100' 
                : 'brightness-0 invert opacity-80 group-hover:brightness-100 group-hover:invert-0 group-hover:opacity-100'
            }`}
          />
        </div>
        <span 
          className={`${isMobile ? 'text-[10px]' : 'text-xs'} font-medium transition-colors duration-300 
                     whitespace-nowrap ${
            isActive 
              ? 'text-yellow-400' 
              : 'text-gray-400 group-hover:text-yellow-400'
          }`}
        >
          {text}
        </span>
      </Link>
    );
  };

  const AuthButtons = () => (
    <div className={`flex items-center gap-2 ${isMobile ? 'ml-1' : 'ml-4'}`}>
      <Link 
        to="/login" 
        className={`${isMobile ? 'h-7 px-2 text-xs' : 'h-8 px-3 text-sm'}
                   relative isolate overflow-hidden min-w-[60px]
                   flex items-center justify-center
                   font-medium text-center rounded-lg 
                   hover:shadow-[0_0_20px_rgba(168,85,247,0.4)]
                   transition-all duration-300 group`}
      >
        {/* Gradient border element */}
        <div className="absolute -z-10 inset-0 rounded-lg bg-gradient-to-r from-purple-600 to-blue-600" />
        {/* Background element */}
        <div className="absolute -z-10 inset-[1px] rounded-[7px] bg-gray-900 bg-opacity-90 backdrop-blur-sm" />
        <span className="relative z-10 group-hover:text-purple-600">Login</span>
      </Link>
      <Link 
        to="/signup" 
        className={`${isMobile ? 'h-7 px-2 text-xs' : 'h-8 px-3 text-sm'}
                   min-w-[60px] flex items-center justify-center
                   font-medium text-center text-white rounded-lg 
                   transition-all duration-300
                   bg-gradient-to-r from-blue-600 to-purple-600 
                   hover:shadow-[0_0_20px_rgba(168,85,247,0.4)]
                   hover:from-blue-500 hover:to-purple-500`}
      >
        Sign Up
      </Link>
    </div>
  );

  const UserMenu = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const dropdownRef = useRef(null);
    const buttonRef = useRef(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isClosing, setIsClosing] = useState(false);

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (!isDropdownOpen || isClosing) return;
        
        if (dropdownRef.current && 
            buttonRef.current &&
            !dropdownRef.current.contains(event.target) &&
            !buttonRef.current.contains(event.target)) {
          setIsClosing(true);
          setTimeout(() => {
            setIsDropdownOpen(false);
            setIsClosing(false);
          }, 150);
          
          // Allow the event to continue propagating to handle navigation
          // We need this small delay to ensure React processes state updates first
          setTimeout(() => {
            const link = event.target.closest('a');
            if (link) {
              link.click();
            }
          }, 0);
        }
      };

      // Add click listener
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('touchstart', handleClickOutside);

      // Cleanup
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
        document.removeEventListener('touchstart', handleClickOutside);
      };
    }, [isDropdownOpen, isClosing]); // Added isClosing to dependencies

    const handleMouseEnter = () => {
      if (!isMobile) {
        if (timeoutRef.current) clearTimeout(timeoutRef.current);
        setIsDropdownOpen(true);
      }
    };

    const handleMouseLeave = () => {
      if (!isMobile) {
        timeoutRef.current = setTimeout(() => {
          setIsDropdownOpen(false);
        }, 150);
      }
    };

    const handleClick = () => {
      if (isMobile) {
        setIsDropdownOpen(!isDropdownOpen);
      } else {
        navigate(`/u/${user.username}`);
      }
    };

    const handleLogout = () => {
      dispatch(logout());
      setIsDropdownOpen(false);
      navigate('/');
    };

    return (
      <div 
        className="relative ml-2"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <button 
          ref={buttonRef}
          onClick={handleClick}
          className={`flex items-center transition-colors duration-300 group cursor-pointer
                    ${isMobile ? 'py-1' : 'py-2'}
                    ${isPathActive(`/u/${user.username}`) || isPathActive('/settings')
            ? 'text-yellow-400'
            : 'text-gray-400 hover:text-yellow-400'
          }`}
        >
          <div className={`relative ${isMobile ? 'w-6 h-6' : 'w-8 h-8'} mr-2`}>
            <img 
              src="/user_profile_icon.png" 
              alt="User Profile" 
              className={`w-full h-full transition-all duration-300 group-hover:scale-110 filter ${
                isPathActive(`/u/${user.username}`) || isPathActive('/settings')
                  ? 'brightness-100 invert-0 opacity-100'
                  : 'brightness-0 invert opacity-80 group-hover:brightness-100 group-hover:invert-0 group-hover:opacity-100'
              }`}
            />
          </div>
          <span className={`${isMobile ? 'text-xs' : 'text-sm'} font-medium max-w-[80px] truncate`}>
            {user.username}
          </span>
        </button>
        
        {isDropdownOpen && (
          <div 
            ref={dropdownRef}
            className={`absolute right-0 mt-1 w-48 bg-gray-800 rounded-md shadow-lg py-1 z-10 border border-gray-700
                      transition-opacity duration-150
                      ${isClosing ? 'opacity-0' : 'opacity-100'}`}
          >
            <Link 
              to={`/u/${user.username}`} 
              className="block px-4 py-2 text-sm text-gray-400 hover:bg-gray-700 hover:text-yellow-400 
                      transition-colors duration-300 flex items-center"
            >
              <img 
                src="/user_profile_icon.png" 
                alt="Profile"
                className="w-4 h-4 mr-2 brightness-0 invert opacity-80"
              />
              User Profile
            </Link>
            <Link 
              to="/settings" 
              className="block px-4 py-2 text-sm text-gray-400 hover:bg-gray-700 hover:text-yellow-400 
                      transition-colors duration-300 flex items-center"
            >
              <Settings size={16} className="mr-2" />
              Settings
            </Link>
            <button 
              onClick={handleLogout}
              className="w-full text-left px-4 py-2 text-sm text-gray-400 hover:bg-gray-700 
                      hover:text-yellow-400 transition-colors duration-300 flex items-center"
            >
              <LogOut size={16} className="mr-2" />
              Logout
            </button>
          </div>
        )}
      </div>
    );
  };

  return (
    <nav className="bg-gray-900 bg-opacity-60 fixed top-0 left-0 right-0 z-50 border-b border-purple-950 shadow-lg">
      <div className="max-w-7xl mx-auto px-2 py-3">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2 md:gap-6">
            <NavIcon src="/cosmic_quests_icon.png" alt="Home" text="Home" to="/" />
            <NavIcon src="/quests_icon.png" alt="Quests" text="Quests" to={["/quests", "/quest/"]} />
            <NavIcon src="/feed_icon.png" alt="Feed" text="Feed" to="/feed" />
            <NavIcon src="/leaderboards_icon.png" alt="Leaderboards" text="Leaderboards" to="/leaderboard" />
          </div>
          
          {isAuthenticated && user ? (
            <UserMenu />
          ) : (
            <AuthButtons />
          )}
        </div>
      </div>
    </nav>
  );
};

export default NavBar;