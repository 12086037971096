import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { Star, Heart, Eye, Clock, User, X, RefreshCcw, BadgeHelp } from 'lucide-react';
import { connect } from 'react-redux';
import SpaceBackground from '../SpaceBackground';
import { generateQuestSlug } from '../../utils/Utils';
import { toast } from 'react-toastify';

const MILLISECONDS_BEFORE_ADDING_VIEW = 5000;
const FEED_REFRESH_INTERVAL = 60000; 

const truncateText = (text, maxLength = 200) => {
  if (text.length <= maxLength) return text;
  return text.slice(0, maxLength).trim() + '...';
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });
};

const UserNameDisplay = ({ entry }) => {
  if (entry.user_name && entry.user_profile_link) {
    return (
      <Link 
        to={`/u/${entry.user_profile_link}`}
        className="text-white-500 text-lg hover:text-yellow-500 transition-colors duration-300 font-medium"
        onClick={e => e.stopPropagation()}
      >
        {entry.user_name}
      </Link>
    );
  }
  
  return (
    <span className="text-gray-300 font-medium italic">
      {entry.user_name || 'Anonymous Explorer'}
    </span>
  );
};

const SubmissionModal = ({ entry, onClose, onLikeClick, isAuthenticated }) => {
  const [hasTrackedView, setHasTrackedView] = useState(false);
  const viewTimeoutRef = React.useRef(null);

  useEffect(() => {
    // Reset view tracking when entry changes
    setHasTrackedView(false);
  }, [entry?.submission_id]);

  // Set up view tracking when modal opens
  useEffect(() => {
    if (!entry || !isAuthenticated || hasTrackedView) {
      return;
    }

    viewTimeoutRef.current = setTimeout(async () => {
      try {
        await axios.post(`/api/v1/submissions/${entry.submission_id}/view/`);
        setHasTrackedView(true);
        if (entry.views_count !== undefined) {
          entry.views_count += 1;
        }
      } catch (error) {
        console.error('Error tracking submission view:', error);
      }
    }, MILLISECONDS_BEFORE_ADDING_VIEW);

    return () => {
      if (viewTimeoutRef.current) {
        clearTimeout(viewTimeoutRef.current);
      }
    };
  }, [entry, isAuthenticated, hasTrackedView]);

  if (!entry) return null;

  const handleLikeClick = (e) => {
    e.stopPropagation();
    if (onLikeClick) {
      onLikeClick(entry.submission_id);
    }
  };

  return (
    <div 
      className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm z-50 flex items-center justify-center py-8 sm:py-16 px-2 sm:px-8"
      onClick={onClose}
    >
      <div className="relative w-full max-w-4xl">
        {/* Close button */}
        <button 
          onClick={(e) => {
            e.stopPropagation();
            onClose();
          }}
          className="absolute right-1 top-1 z-10 text-gray-400 hover:text-white transition-colors"
          aria-label="Close modal"
        >
          <X size={24} strokeWidth={2.5} />
        </button>

        {/* Modal content */}
        <div 
          className="bg-gray-800 rounded-lg w-full max-h-[85vh] sm:max-h-[90vh] overflow-y-auto"
          onClick={e => e.stopPropagation()}
        >
          <div className="p-4 sm:p-6">
            {/* Header Section - Updated styling */}
            <div className="flex flex-col space-y-2 sm:space-y-0 sm:flex-row sm:items-center mb-4">
              <div className="flex items-center space-x-2">
                <User size={20} className="text-yellow-500 flex-shrink-0" />
                <UserNameDisplay entry={entry} />
              </div>
              <span className="text-gray-400 text-sm sm:ml-2">
                submitted on {formatDate(entry.submission_datetime)}
              </span>
            </div>

            {/* Quest Title & Status - Updated styling */}
            <div className="mb-4">
              <div className="flex items-center justify-between gap-2">
                <Link 
                  to={`/quest/${generateQuestSlug(entry.quest.prefix, entry.quest.quest_title)}`}
                  className="text-xl font-semibold text-indigo-600 hover:text-purple-600 transition-colors duration-300 break-words"
                  onClick={e => e.stopPropagation()}
                >
                  {entry.quest.quest_title}
                </Link>
                <span className={`inline-flex items-center justify-center px-2 sm:px-3 py-0.5 sm:py-1 rounded-full text-xs sm:text-sm font-medium whitespace-nowrap ${
                  entry.quest_is_active 
                    ? 'bg-green-500/20 text-green-400' 
                    : 'bg-gray-500/20 text-gray-400'
                }`}>
                  {entry.quest_is_active ? 'Active Quest' : 'Completed Quest'}
                </span>
              </div>
            </div>

            {/* Submission Content */}
            <div className="bg-gray-900/50 rounded-lg p-4 sm:p-6 mb-4">
              <p className="text-white-200 text-justify leading-relaxed whitespace-pre-wrap break-words">
                {entry.user_submission}
              </p>
            </div>

            {/* Stats Grid */}
            <div className="grid grid-cols-3 gap-4 sm:gap-6 p-3 bg-gray-900/30 rounded-lg">
              <div className="flex flex-col sm:flex-row items-center justify-center">
                <div className="grid grid-cols-[auto_1fr] items-center gap-2">
                  <img 
                    src="/speck_small.png"
                    alt="SPECKS" 
                    className="w-5 h-5"
                  />
                  <div className="flex items-center justify-center space-x-2">
                    {entry.specks_earned > entry.quest.base_reward ? (
                      // Show total reward if value reward exists
                      <span className="text-gray-200 font-medium">{entry.specks_earned} SPECKS</span>
                    ) : (
                      // Show base reward + question mark
                      <div className="flex items-center">
                        <span className="text-gray-200 font-medium">{entry.quest.base_reward}</span>
                        <span className="text-gray-200 mx-0.5">+</span>
                        <BadgeHelp 
                          size={16} 
                          className="text-yellow-400 cursor-help"
                          title={entry.quest.quest_type === 'permanent' 
                            ? "Additional reward will be calculated periodically" 
                            : "Additional reward will be calculated when the quest ends"}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              
              <div 
                className={`flex items-center justify-center ${
                  isAuthenticated ? 'cursor-pointer' : ''
                }`}
                onClick={isAuthenticated ? handleLikeClick : undefined}
              >
                <div className="flex items-center space-x-2 whitespace-nowrap">
                  <Heart 
                    size={18} 
                    className={`${entry.is_liked ? 'text-red-500 fill-red-500' : 'text-gray-400'} 
                      transition-all duration-300 ${isAuthenticated ? 'hover:scale-110' : ''}`}
                  />
                  <span className="text-gray-200 font-medium">{entry.likes_count} likes</span>
                </div>
              </div>

              <div className="flex items-center justify-center">
                <div className="flex items-center space-x-2 whitespace-nowrap">
                  <Eye size={18} className="text-gray-400" />
                  <span className="text-gray-200 font-medium">{entry.views_count} views</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const FeedEntry = ({ entry, isAuthenticated, onLikeClick, onEntryClick }) => {
  const handleLikeClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onLikeClick(entry.submission_id);
  };

  return (
      <div 
        className="bg-gray-800 bg-opacity-80 rounded-lg shadow-lg p-4 sm:p-6 mb-4 sm:mb-6 w-[95vw] sm:w-full sm:max-w-[1700px] hover:bg-opacity-90 transition-all duration-300 cursor-pointer"
        onClick={() => onEntryClick(entry)}
      >
        {/* Header Section - Updated layout */}
        <div className="flex flex-col sm:flex-row justify-between items-start mb-4 gap-2">
          <div className="flex flex-col space-y-2 sm:space-y-0 sm:flex-row sm:items-center">
            <div className="flex items-center space-x-1">
              <User size={20} className="text-yellow-500 flex-shrink-0" />
              <UserNameDisplay entry={entry} />
            </div>
            <span className="text-gray-400 text-sm sm:ml-2">
              submitted on {formatDate(entry.submission_datetime)}
            </span>
          </div>
          {/* Time info - hidden on mobile */}
          <div className="hidden sm:flex items-center space-x-2 text-sm text-gray-400">
            <Clock size={16} className="flex-shrink-0" />
            <span className="whitespace-nowrap">
              {entry.quest_is_active ? 'Active until' : 'Quest ended -'} {formatDate(entry.quest_active_to)}
            </span>
          </div>
        </div>


      {/* Quest Title & Status */}
      <div className="mb-4">
        <div className="flex items-center justify-between">
          <Link 
            to={`/quest/${generateQuestSlug(entry.quest.prefix, entry.quest.quest_title)}`}
            className="text-xl font-semibold text-indigo-600 hover:text-purple-600 transition-colors duration-300 break-words"
            onClick={e => e.stopPropagation()}
          >
            {entry.quest.quest_title}
          </Link>
          <span className={`inline-flex items-center justify-center px-2 sm:px-3 py-2 sm:py-1 rounded-full text-xs sm:text-sm font-medium whitespace-nowrap ${
              entry.quest_is_active 
                ? 'bg-green-500/20 text-green-400' 
                : 'bg-gray-500/20 text-gray-400'
            }`}>
            {entry.quest_is_active ? 'Active Quest' : 'Completed Quest'}
          </span>
        </div>
      </div>

      {/* Truncated Submission Content */}
      <div className="bg-gray-900/50 rounded-lg p-4 mb-4">
        <p className="text-gray-200 text-justify leading-relaxed whitespace-pre-wrap break-words">
          {truncateText(entry.user_submission)}
        </p>
      </div>

      {/* Stats Grid */}
      <div className="grid grid-cols-3 gap-4 sm:gap-6 p-3 bg-gray-900/30 rounded-lg">
        <div className="flex flex-col sm:flex-row items-center justify-center">
          <div className="grid grid-cols-[auto_1fr] items-center gap-2">
            <img 
              src="/speck_small.png"
              alt="SPECKS" 
              className="w-5 h-5"
            />
            <div className="flex items-center justify-center space-x-2">
              {entry.specks_earned > entry.quest.base_reward ? (
                // Show total reward if value reward exists
                <span className="text-gray-200 font-medium">{entry.specks_earned+entry.quest.base_reward} SPECKS</span>
              ) : (
                // Show base reward + question mark
                <div className="flex items-center">
                  <span className="text-gray-200 font-medium">{entry.quest.base_reward}</span>
                  <span className="text-gray-200 mx-0.5">+</span>
                  <BadgeHelp 
                    size={16} 
                    className="text-yellow-400 cursor-help"
                    title={entry.quest.quest_type === 'permanent' 
                      ? "Additional reward will be calculated periodically" 
                      : "Additional reward will be calculated when the quest ends"}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        
        <div 
          className={`flex items-center justify-center ${
            isAuthenticated ? 'cursor-pointer' : ''
          }`}
          onClick={isAuthenticated ? handleLikeClick : undefined}
        >
          <div className="flex items-center space-x-2 whitespace-nowrap">
            <Heart 
              size={18} 
              className={`${entry.is_liked ? 'text-red-500 fill-red-500' : 'text-gray-400'} 
                transition-all duration-300 ${isAuthenticated ? 'hover:scale-110' : ''}`}
            />
            <span className="text-gray-200 font-medium">{entry.likes_count} likes</span>
          </div>
        </div>

        <div className="flex items-center justify-center">
          <div className="flex items-center space-x-2 whitespace-nowrap">
            <Eye size={18} className="text-gray-400" />
            <span className="text-gray-200 font-medium">{entry.views_count} views</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const Feed = ({ isAuthenticated }) => {
  const navigate = useNavigate();
  const [entries, setEntries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const fetchEntries = useCallback(async (showLoadingState = true) => {
    if (showLoadingState) {
      setIsLoading(true);
    } else {
      setIsRefreshing(true);
    }

    try {
      const response = await axios.get('/api/v1/feed/');
      setEntries(response.data);
    } catch (error) {
      console.error('Error fetching feed entries:', error);
      toast.error('Failed to load feed entries');
    } finally {
      setIsLoading(false);
      setIsRefreshing(false);
    }
  }, []);

  // Initial load
  useEffect(() => {
    fetchEntries();
  }, [fetchEntries]);

  // Set up periodic refresh
  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchEntries(false); // Don't show loading state for periodic refreshes
    }, FEED_REFRESH_INTERVAL);

    return () => clearInterval(intervalId);
  }, [fetchEntries]);

  const handleRefreshClick = () => {
    fetchEntries(false);
  };

  const handleLikeClick = async (submissionId) => {
    if (!isAuthenticated) {
      navigate('/login');
      return;
    }

    try {
      await axios.post(`/api/v1/submissions/${submissionId}/like/`);
      const updateEntries = entries => entries.map(entry => {
        if (entry.submission_id === submissionId) {
          return {
            ...entry,
            is_liked: !entry.is_liked,
            likes_count: entry.likes_count + (entry.is_liked ? -1 : 1)
          };
        }
        return entry;
      });

      setEntries(updateEntries);
      setSelectedEntry(prev => 
        prev?.submission_id === submissionId 
          ? updateEntries([prev])[0]
          : prev
      );
    } catch (error) {
      console.error('Error liking submission:', error);
      toast.error('Failed to update like status');
    }
  };

  const updateEntryViewCount = useCallback((submissionId) => {
    setEntries(prevEntries => 
      prevEntries.map(entry => {
        if (entry.submission_id === submissionId) {
          return {
            ...entry,
            views_count: entry.views_count + 1
          };
        }
        return entry;
      })
    );
  }, []);

  const handleModalClose = useCallback(() => {
    setSelectedEntry(null);
  }, []);

  return (
    <>
      <SpaceBackground />
      <div className="relative min-h-screen text-white overflow-y-auto">
        <div className="min-h-screen flex flex-col items-center p-4 sm:p-8 max-w-[1700px] md:max-w-[1000px] mx-auto">
          <h2 className="text-4xl font-bold items-center text-center bg-gradient-to-r from-indigo-600 to-purple-600 bg-clip-text text-transparent transition-opacity">
            Cosmic Feed
          </h2>

          <div className="w-full flex flex-col items-center px-0 sm:px-4"> 
            {isLoading ? (
              <div className="flex flex-col items-center justify-center py-12">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500 mb-4"></div>
                <p className="text-gray-400">Loading feed entries...</p>
              </div>
            ) : entries.length > 0 ? (
              entries.map((entry) => (
                <FeedEntry 
                  key={entry.id} 
                  entry={entry} 
                  isAuthenticated={isAuthenticated}
                  onLikeClick={handleLikeClick}
                  onEntryClick={setSelectedEntry}
                />
              ))
            ) : (
              <div className="text-center py-12">
                <p className="text-gray-400 text-lg">No entries available</p>
                <p className="text-gray-500 mt-2">Check back later for new submissions</p>
              </div>
            )}
          </div>
        </div>

        {selectedEntry && (
          <SubmissionModal
            entry={selectedEntry}
            onClose={handleModalClose}
            onLikeClick={handleLikeClick}
            isAuthenticated={isAuthenticated}
            onViewCountUpdate={() => updateEntryViewCount(selectedEntry.submission_id)}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps)(Feed);