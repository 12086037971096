import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import { fetchUserLeaderboard } from './LeaderboardActions';
import { Trophy, User, Star, ArrowUp, ArrowRight, ArrowDown } from 'lucide-react';
import { Link } from 'react-router-dom';
import SpaceBackground from '../SpaceBackground';

const UserLeaderboard = ({ 
  auth: { user, isAuthenticated },
  leaderboardState,
  fetchUserLeaderboard
}) => {
  const userRowRef = useRef(null);

  useEffect(() => {
    fetchUserLeaderboard();
  }, [fetchUserLeaderboard]);

  useEffect(() => {
    if (userRowRef.current) {
      const isInViewport = (element) => {
        const rect = element.getBoundingClientRect();
        return (
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
      };
      if (!isInViewport(userRowRef.current)) {
        userRowRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [leaderboardState.data]);

  // Inside the component, add this function
  const renderMovementIndicator = (movement) => {
    if (!movement) return null;
    
    switch (movement) {
      case 'promote':
        return <ArrowUp className="w-4 h-4 sm:w-5 sm:h-5 mr-1 text-green-500" />;
      case 'stay':
        return <ArrowRight className="w-4 h-4 sm:w-5 sm:h-5 mr-1 text-yellow-500" />;
      case 'relegate':
        return <ArrowDown className="w-4 h-4 sm:w-5 sm:h-5 mr-1 text-red-500" />;
      default:
        return null;
    }
  };

  const renderContent = () => {
    if (!isAuthenticated) {
      return (
        <div className="bg-gray-800 bg-opacity-80 p-6 rounded-lg shadow-lg text-center">
          <h2 className="text-2xl font-semibold mb-4">Authentication Required</h2>
          <p>You need to be logged in to view and participate in leaderboards.</p>
          <div className="mt-4">
            <Link 
              to="/login" 
              className="text-indigo-600 hover:text-yellow-500 transition-colors duration-300"
            >
              Click here to log in
            </Link>
          </div>
        </div>
      );
    }

    if (leaderboardState.loading) {
      return <div className="text-white text-center py-8">Loading leaderboard...</div>;
    }

    if (leaderboardState.error) {
      return <div className="text-center py-8">{leaderboardState.error}</div>;
    }

    if (!leaderboardState.data || !leaderboardState.data.leaderboard || !leaderboardState.data.records) {
      return (
        <div className="bg-gray-800 bg-opacity-80 p-6 rounded-lg shadow-lg text-center">
          <h2 className="text-2xl font-semibold mb-4">No Active Leaderboard</h2>
          <p>There is no active leaderboard at the moment.</p>
          <p className="mt-4">Complete quests to join a leaderboard and see your ranking!</p>
        </div>
      );
    }

    const { leaderboard, records } = leaderboardState.data;

    return (
      <>
        <p className="text-base sm:text-xl mb-4 sm:mb-8 font-sans">{leaderboard.description}</p>
        <div className="bg-gray-800 bg-opacity-80 p-3 sm:p-6 rounded-lg shadow-lg w-full">
          <h2 className="text-lg sm:text-2xl font-semibold mb-4 flex items-center px-1">
            <Trophy className="mr-2 text-yellow-400" />
            {leaderboard.title}
          </h2>
          
          <div className="w-full overflow-x-auto">
            <table className="w-full min-w-full">
            <colgroup>
              <col className="w-[15%] sm:w-[20%]" /> 
              <col className="w-[60%] sm:w-[50%]" />
              <col className="w-[25%]" />
            </colgroup>
              <thead>
                <tr className="text-left text-xs sm:text-base">
                  <th className="py-2 px-1 sm:px-4">Rank</th>
                  <th className="py-2 px-1 sm:px-4">Explorer</th>
                  <th className="py-2 px-1 sm:px-4 text-right sm:text-left">Score</th>
                </tr>
              </thead>
              <tbody>
                {records.map((record, index) => {
                  const isCurrentUser = isAuthenticated && user && record.user.username === user.username;
                  return (
                    <tr 
                      key={record.id}
                      ref={isCurrentUser ? userRowRef : null}
                      className={`
                        ${isCurrentUser 
                          ? 'bg-indigo-600 bg-opacity-50 hover:shadow-[0_0_20px_rgba(255,191,50,0.5)] backdrop-blur-sm' 
                          : index % 2 === 0 
                            ? 'bg-gray-700 bg-opacity-50' 
                            : ''
                        }
                      `}
                    >
                      <td className="py-2 px-3 sm:px-4">
                        <div className="flex items-center">
                          {renderMovementIndicator(record.movement)}
                          <span className="text-xs sm:text-lg text-gray-200">{record.user_rank}</span>
                        </div>
                      </td>
                      <td className="py-2 px-2 sm:px-4">
                        <div className="flex items-center space-x-1 sm:space-x-2">
                          <User className="w-4 h-4 sm:w-5 sm:h-5 flex-shrink-0" />
                          <div className="truncate max-w-[100px] sm:max-w-none">
                            {record.user.username && (
                              <Link 
                                to={`/u/${record.user.username}`}
                                className="text-gray-200 hover:text-yellow-500 transition-colors duration-300 text-s sm:text-lg"
                              >
                                {record.user.username}
                              </Link>
                            )}
                          </div>
                        </div>
                      </td>
                      <td className="py-2 px-2 sm:px-4 text-right sm:text-left">
                        <span className="text-s sm:text-lg text-gray-200">{record.user_score}</span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <SpaceBackground />
      <div className="relative min-h-screen text-white">
        <main className="w-[88vw] sm:w-full max-w-4xl mx-auto px-0 sm:px-2">
          <h1 className="text-4xl font-bold mb-4 bg-gradient-to-r from-yellow-600 to-purple-800 bg-clip-text text-transparent transition-opacity">Leaderboard</h1>
          {renderContent()}
        </main>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  leaderboardState: state.leaderboards
});

export default connect(mapStateToProps, { fetchUserLeaderboard })(UserLeaderboard);