import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';
import { login } from './LoginActions';
import SpaceBackground from '../SpaceBackground';
import { submitQuest } from '../quests/QuestsActions';
import { DEFAULT_FEED_PRIVACY } from '../quests/QuestDetail';
import { ArrowRight, LogIn } from 'lucide-react';
import { toast } from 'react-toastify';
import { handleGoogleAuth } from '../../utils/AuthUtils';
import SavedAnswerNotification from '../ui/SavedAnswerNotification';
import SocialAuthButton from '../auth/SocialAuthButton';


const Login = ({ login, isAuthenticated }) => {
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });
  const [captchaToken, setCaptchaToken] = useState('');

  const { username, password } = formData;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleGoogleSuccess = async (credentialResponse) => {
    await handleGoogleAuth(credentialResponse, dispatch, navigate);
  };

  const handlePostLoginActions = async () => {
    // Handle guest submission after successful login
    const guestAnswer = localStorage.getItem('guestAnswer');
    const guestQuestId = localStorage.getItem('guestQuestId');
    const guestQuestSlug = localStorage.getItem('guestQuestSlug');
    const guestPrivacy = localStorage.getItem('guestPrivacy') || DEFAULT_FEED_PRIVACY;

    if (guestAnswer && guestQuestId) {
      try {
        await dispatch(submitQuest(guestQuestId, guestAnswer, guestPrivacy));
        localStorage.removeItem('guestAnswer');
        localStorage.removeItem('guestQuestId');
        localStorage.removeItem('guestQuestSlug');
        localStorage.removeItem('guestPrivacy');
        if (guestQuestSlug) {
          navigate(`/quest/${guestQuestSlug}`);
          return;
        }
      } catch (submitError) {
        console.error('Failed to submit guest quest:', submitError);
        toast.error("Failed to submit your answer. Please try again from the quest page.");
      }
    }
    navigate('/');
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const loginSuccess = await login({ ...formData, captcha: captchaToken });
      if (loginSuccess) {
        handlePostLoginActions();
      }
    } catch (error) {
      toast.error("Login failed: " + error.message);
    }
  };

  if (isAuthenticated) {
    return <Navigate to="/quests" />;
  }

  return (
    <>
      <SpaceBackground />
      <div className="relative flex items-start justify-center min-h-screen text-white pt-8">
        <div className="relative w-full max-w-md mx-4">
          <div className="bg-gray-800/80 backdrop-blur-sm rounded-xl p-6">
            <h1 className="text-3xl font-bold mb-6 bg-gradient-to-r from-blue-500 to-purple-600 bg-clip-text text-transparent">
              Login to Abyss
            </h1>
            {localStorage.getItem('guestAnswer') && <SavedAnswerNotification />}

            {/* Regular Login Form - Now First */}
            <form onSubmit={onSubmit} className="space-y-4">
              <div>
                <label className="block text-md font-semibold mb-1 text-gray-300" htmlFor="username">
                  Username
                </label>
                <input
                  className="w-full bg-gray-700/50 border border-gray-900 rounded-lg py-2 px-3 
                           text-white-200 leading-tight focus:outline-none focus:border-purple-500 
                           focus:ring-2 focus:ring-purple-500/50 transition-all duration-300"
                  id="username"
                  type="text"
                  placeholder="Enter your username"
                  name="username"
                  value={username}
                  onChange={onChange}
                  required
                />
              </div>
              <div>
                <label className="block text-md font-semibold mb-2 text-gray-300" htmlFor="password">
                  Password
                </label>
                <input
                  className="w-full bg-gray-700/50 border border-gray-600 rounded-lg py-2 px-3 
                           text-gray-200 mb-3 leading-tight focus:outline-none focus:border-purple-500 
                           focus:ring-2 focus:ring-purple-500/50 transition-all duration-300"
                  id="password"
                  type="password"
                  placeholder="Enter your password"
                  name="password"
                  value={password}
                  onChange={onChange}
                  minLength="6"
                  required
                />
              </div>

              <div className="mt-4 flex justify-center">
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY}
                  onChange={handleCaptchaChange}
                  theme='dark'
                />
              </div>
              <div className="flex items-center justify-between">
                <button
                  className="px-6 py-2 text-sm font-medium text-white rounded-lg transition-all duration-300
                            bg-gradient-to-r from-blue-600 to-purple-600
                            hover:from-blue-500 hover:to-purple-500
                            hover:shadow-[0_0_20px_rgba(168,85,247,0.4)]
                            backdrop-blur-sm flex items-center"
                  type="submit"
                >
                  <LogIn className="mr-2" size={18} />
                  Log In
                </button>
                <Link
                  to="/signup"
                  className="inline-flex items-center text-sm font-medium text-blue-600 
                            hover:text-purple-600 transition-colors duration-300"
                >
                  Need an account? <ArrowRight className="ml-1" size={16} />
                </Link>
              </div>
            </form>

            {/* Social Login Options - Now Last */}
            <div className="mt-6">
              <div className="relative text-center">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-600"></div>
                </div>
                <div className="relative">
                  <span className="px-2 text-sm text-gray-400 bg-gray-800/80">Or continue with</span>
                </div>
              </div>

              <div className="mt-6 flex flex-col gap-4 items-center">
                  <SocialAuthButton
                    provider="google"
                    onSuccess={(response) => handleGoogleAuth(response, dispatch, navigate)}
                    onFailure={() => toast.error('Google Authentication Failed')}
                  />
                  <SocialAuthButton
                    provider="twitter"
                    onSuccess={(data) => {
                      console.log('Twitter login success');
                    }}
                    onFailure={(error) => {
                      console.error('Login error:', error);
                      toast.error('Twitter login failed: ' + (error.message || 'Unknown error'));
                    }}
                  />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};


const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { login })(Login);