import React, { useState, useCallback, useEffect } from 'react';

const PrivacyToggleWithTooltip = ({ value, onChange, options }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [activeOption, setActiveOption] = useState(null);
  const [arrowPosition, setArrowPosition] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640); // sm breakpoint
  const mobileArrowsShift = 15;
  const fullWidthArrowsShift = 15;
  
  const tooltipContent = {
    'PUBLIC': 'Your responses appear with your username in the feed. Other explorers will know that you gave those responses.',
    'ANONYMOUS': 'Your responses appear in the feed without your username. Share your thoughts while maintaining privacy.',
    'PRIVATE': 'Your responses won\'t appear in the global feed. Perfect for those who prefer to explore quietly.'
  };

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleOptionHover = useCallback((option, event) => {
    const button = event.currentTarget;
    const container = button.closest('.privacy-toggle-container');
    const buttonRect = button.getBoundingClientRect();
    const containerRect = container.getBoundingClientRect();

    // Calculate the position differently for mobile and desktop
    const position = isMobile
      ? buttonRect.left + (buttonRect.width / 2) - containerRect.left - mobileArrowsShift
      : buttonRect.left + (buttonRect.width / 2) - containerRect.left - fullWidthArrowsShift;

    setArrowPosition(position);
    setActiveOption(option);
    setShowTooltip(true);
  }, [isMobile]);

  return (
    <div className="relative w-full sm:w-auto">
      <div className="flex justify-center sm:justify-start">
        <div className="privacy-toggle-container inline-flex rounded-lg bg-gray-800 p-0.5 sm:p-1">
          {Object.entries(options).map(([key, label]) => (
            <div
              key={key}
              className="relative"
              onMouseEnter={(e) => handleOptionHover(key, e)}
              onMouseLeave={() => setShowTooltip(false)}
              onTouchStart={(e) => handleOptionHover(key, e)}
              onTouchEnd={() => setShowTooltip(false)}
            >
              <button
                onClick={() => onChange(key)}
                className={`px-2 sm:px-4 py-1.5 sm:py-2 text-xs sm:text-sm rounded-md transition-all duration-200 whitespace-nowrap ${
                  value === key
                    ? 'bg-indigo-600 text-white'
                    : 'text-gray-400 hover:text-white'
                }`}
              >
                <div className="flex items-center gap-1">
                  {label}
                </div>
              </button>
            </div>
          ))}
        </div>
      </div>

      {/* Tooltip adjusted for mobile */}
      {showTooltip && activeOption && (
        <div className="absolute z-10 w-60 sm:w-72 p-3 sm:p-4 bg-indigo-950 rounded-lg shadow-xl border border-indigo-700
          left-1/2 transform -translate-x-1/2 mt-3">
          <p className="text-gray-200 text-xs sm:text-sm pt-1 sm:pt-2">
            {tooltipContent[activeOption]}
          </p>
        </div>
      )}
    </div>
  );
};

export default PrivacyToggleWithTooltip;