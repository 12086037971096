/**
 * Predict user movements (promotion/relegation) for a leaderboard.
 * 
 * @param {Object} leaderboard - The leaderboard object with league config
 * @param {Array} records - Array of leaderboard records
 * @returns {Object} - Mapping of username to movement type
 */
export const predictUserMovements = (leaderboard, records) => {
    if (!leaderboard || !records || records.length === 0) {
      return {};
    }
  
    const league = leaderboard.league;
    const config = league.config;
    
    // Group records by rank for tie handling
    const rankGroups = {};
    records.forEach(record => {
      if (!rankGroups[record.user_rank]) {
        rankGroups[record.user_rank] = [];
      }
      rankGroups[record.user_rank].push(record);
    });
    
    // If all users have the same rank, everyone stays
    if (Object.keys(rankGroups).length === 1) {
      return records.reduce((acc, record) => {
        acc[record.user.username] = 'stay';
        return acc;
      }, {});
    }
    
    // Identify promotions
    const { promotions } = determinePromotions(league, rankGroups, records, config);
    const promotionUsernames = new Set(promotions.map(r => r.user.username));
    
    // Identify relegations (from users not marked for promotion)
    const remainingRecords = records.filter(r => !promotionUsernames.has(r.user.username));
    const { relegations } = determineRelegations(league, rankGroups, remainingRecords, config);
    const relegationUsernames = new Set(relegations.map(r => r.user.username));
    
    // Build the movement predictions
    const predictions = {};
    
    // Add all predictions
    records.forEach(record => {
      const username = record.user.username;
      if (promotionUsernames.has(username)) {
        predictions[username] = 'promote';
      } else if (relegationUsernames.has(username)) {
        predictions[username] = 'relegate';
      } else {
        predictions[username] = 'stay';
      }
    });
    
    return predictions;
  };
  
  /**
   * Determine which users should be promoted based on league configuration.
   */
  const determinePromotions = (league, rankGroups, allRecords, config) => {
    const promotionRate = config.promotion_rate || 0;
    const totalUsers = allRecords.length;
    
    // Check if promotion is possible
    if (promotionRate <= 0 || league.title === 'ABYSS' || totalUsers === 0) {
      return { promotions: [] };
    }
    
    // Calculate promotion count
    const promoteCount = Math.max(1, Math.floor(totalUsers * promotionRate));
    
    // Get users to promote including ties
    const sortedRanks = Object.keys(rankGroups).sort((a, b) => a - b); // Best ranks first
    const promotions = [];
    let usersCollected = 0;
    
    for (const rank of sortedRanks) {
      const group = rankGroups[rank];
      
      // If we haven't started collecting yet or this group completes the promotion
      if (usersCollected === 0 || usersCollected + group.length <= promoteCount) {
        promotions.push(...group);
        usersCollected += group.length;
      }
      // Handle tie case - if we started collecting and would exceed by adding this group
      else if (usersCollected > 0) {
        promotions.push(...group);
        usersCollected += group.length;
        break;
      }
      
      if (usersCollected >= promoteCount) {
        break;
      }
    }
    
    return { promotions };
  };
  
  /**
   * Determine which users should be relegated based on league configuration.
   */
  const determineRelegations = (league, rankGroups, eligibleRecords, config) => {
    const relegationRate = config.relegation_rate || 0;
    const totalUsers = eligibleRecords.length;
    
    // Check if relegation is possible
    if (relegationRate <= 0 || league.title === 'BRONZE' || totalUsers === 0) {
      return { relegations: [] };
    }
    
    // Calculate relegation count
    const relegateCount = Math.max(1, Math.floor(totalUsers * relegationRate));
    
    // Regroup eligible records by rank
    const eligibleRankGroups = {};
    eligibleRecords.forEach(record => {
      if (!eligibleRankGroups[record.user_rank]) {
        eligibleRankGroups[record.user_rank] = [];
      }
      eligibleRankGroups[record.user_rank].push(record);
    });
    
    // Get users to relegate accounting for ties
    const sortedRanks = Object.keys(eligibleRankGroups).sort((a, b) => b - a); // Worst ranks first
    const relegations = [];
    let usersCollected = 0;
    
    for (const rank of sortedRanks) {
      const group = eligibleRankGroups[rank];
      
      // If we're already at exact count, stop
      if (usersCollected === relegateCount) {
        break;
      }

      // If we've started collecting and this group would put us over, stop
      if (usersCollected + group.length > relegateCount) {
        break;
      }
      
      // Otherwise include the whole group
      relegations.push(...group);
      usersCollected += group.length;
    }
    
    return { relegations };
  };