import React, { useEffect, useRef, useState } from 'react';
import { motion, usePresence } from 'framer-motion';

const QuestCompletionAnimation = ({ 
  isVisible, 
  onComplete, 
  reward
}) => {
  const [isPresent, safeToRemove] = usePresence();
  const hasInitiatedAnimation = useRef(false);
  const canvasRef = useRef(null);
  const particlesRef = useRef([]);
  const vortexPointsRef = useRef([]);
  const animationFrameRef = useRef();
  const containerRef = useRef(null);
  const [showVortex, setShowVortex] = useState(false);
  const [showReward, setShowReward] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  // Handle presence
  useEffect(() => {
    if (!isPresent) safeToRemove();
  }, [isPresent, safeToRemove]);

  // Main animation sequence
  useEffect(() => {
    if (isVisible && !hasInitiatedAnimation.current) {
      hasInitiatedAnimation.current = true;

      const timeline = {
        startVortex: setTimeout(() => {
          setShowVortex(true);
        }, 200),
        showReward: setTimeout(() => {
          setShowReward(true);
        }, 3200),
        showConfirm: setTimeout(() => {
          setShowConfirm(true);
        }, 4200)
      };

      return () => {
        Object.values(timeline).forEach(clearTimeout);
        hasInitiatedAnimation.current = false;
      };
    }
  }, [isVisible]);

  // Vortex effect
  useEffect(() => {
    if (!showVortex || !canvasRef.current || !containerRef.current) return;

    const canvas = canvasRef.current;
    const container = containerRef.current;
    const ctx = canvas.getContext('2d');
    
    const vortexConfig = {
      radius: 200,
      rotationSpeed: 0.02,
      spiralTightness: 0.15,
      suctionStrength: 0.8,
      expansionRate: 1.002,
      centerX: canvas.width / 2,
      centerY: canvas.height / 2
    };

    const updateCanvasSize = () => {
      canvas.width = container.clientWidth;
      canvas.height = container.clientHeight;
      vortexConfig.centerX = canvas.width / 2;
      vortexConfig.centerY = canvas.height / 2;
    };

    updateCanvasSize();
    window.addEventListener('resize', updateCanvasSize);

    // Initialize effects
    const initializeEffects = () => {
      vortexPointsRef.current = Array(300).fill().map((_, i) => {
        const angle = (i / 300) * Math.PI * 20;
        const radius = i * 0.5;
        return {
          x: Math.cos(angle) * radius,
          y: Math.sin(angle) * radius,
          angle,
          radius,
          speed: 0.1 + Math.random() * 0.4,
          brightness: Math.random() * 0.5 + 0.5,
          color: Math.random() > 0.8 ? 'hsla(60, 100%, 70%, 0.8)' : // Yellow
                 Math.random() > 0.6 ? 'hsla(350, 100%, 70%, 0.8)' : // Coral
                 'hsla(240, 100%, 70%, 0.8)' // Purple/Indigo
        };
      });

      particlesRef.current = Array(150).fill().map(() => ({
        x: Math.random() * canvas.width,
        y: Math.random() * canvas.height,
        size: Math.random() * 2.5 + 0.5,
        speed: Math.random() * 2 + 1,
        angle: Math.random() * Math.PI * 2,
        rotationSpeed: (Math.random() - 0.5) * 0.01,
        color: Math.random() > 0.8 ? 'hsla(60, 80%, 70%, 0.8)' : // Yellow
               Math.random() > 0.6 ? 'hsla(350, 80%, 70%, 0.8)' : // Coral
               'hsla(240, 80%, 70%, 0.8)', // Purple/Indigo
        pulsePhase: Math.random() * Math.PI * 2,
        orbitRadius: Math.random() * 50 + 50
      }));
    };

    initializeEffects();

    const animate = () => {
      ctx.fillStyle = 'rgba(0, 0, 0, 0.15)';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // Draw vortex
      ctx.save();
      ctx.translate(vortexConfig.centerX, vortexConfig.centerY);
      
      vortexPointsRef.current.forEach(point => {
        point.angle += point.speed * vortexConfig.rotationSpeed;
        point.radius *= vortexConfig.expansionRate;
        
        if (point.radius > Math.max(canvas.width, canvas.height)) {
          point.radius = 0;
        }

        const x = Math.cos(point.angle) * point.radius;
        const y = Math.sin(point.angle) * point.radius;
        const opacity = Math.max(0, 0.7 - point.radius / (Math.max(canvas.width, canvas.height) / 2));
        
        ctx.beginPath();
        ctx.arc(x, y, 1.5, 0, Math.PI * 2);
        ctx.fillStyle = point.color.replace('0.8', opacity * point.brightness);
        ctx.fill();
      });
      
      ctx.restore();

      // Draw particles
      particlesRef.current.forEach(particle => {
        const dx = vortexConfig.centerX - particle.x;
        const dy = vortexConfig.centerY - particle.y;
        const dist = Math.sqrt(dx * dx + dy * dy);
        
        const force = Math.max(0, 1 - dist / (300 * vortexConfig.suctionStrength));
        const angle = Math.atan2(dy, dx) + particle.rotationSpeed;
        
        particle.x += (Math.cos(angle) * particle.speed + dx / dist * force * 3);
        particle.y += (Math.sin(angle) * particle.speed + dy / dist * force * 3);
        
        particle.angle += particle.rotationSpeed;
        particle.pulsePhase += 0.05;
        const pulseScale = 1 + Math.sin(particle.pulsePhase) * 0.2;

        ctx.save();
        ctx.translate(particle.x, particle.y);
        ctx.rotate(particle.angle);
        ctx.scale(pulseScale, pulseScale);
        
        ctx.beginPath();
        ctx.arc(0, 0, particle.size, 0, Math.PI * 2);
        ctx.fillStyle = particle.color;
        ctx.fill();
        
        ctx.globalAlpha = 0.3;
        ctx.beginPath();
        ctx.arc(0, 0, particle.size * 2, 0, Math.PI * 2);
        ctx.fillStyle = particle.color;
        ctx.fill();
        
        ctx.restore();

        if (dist < 20) {
          particle.x = Math.random() * canvas.width;
          particle.y = Math.random() * canvas.height;
        }
      });

      animationFrameRef.current = requestAnimationFrame(animate);
    };

    animate();

    return () => {
      cancelAnimationFrame(animationFrameRef.current);
      window.removeEventListener('resize', updateCanvasSize);
    };
  }, [showVortex]);

  const handleConfirm = () => {
    onComplete();
  };

  if (!isVisible) return null;

  return (
    <div 
      ref={containerRef}
      className="fixed inset-0 z-[9999] flex items-start justify-center bg-black/80 backdrop-blur-sm"
    >
      <canvas 
        ref={canvasRef} 
        className="absolute inset-0"
      />

      {showReward && (
        <motion.div
          className="mt-[45vh] w-[75%] max-w-sm mx-4 bg-gray-800/90 backdrop-blur-sm rounded-xl p-4 text-center
                     relative shadow-xl border border-gray-700/30"
          initial={{ scale: 0, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ type: "spring", duration: 0.5 }}
        >
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ delay: 0.2 }}
            className="relative"
          >
            <img 
              src="/speck_icon.png" 
              alt="Specks" 
              className="w-10 h-10 md:w-12 md:h-12 mx-auto mb-2"
            />
            <motion.div
              className="absolute inset-0"
              animate={{
                scale: [1, 1.2, 1],
                opacity: [0.5, 0, 0.5]
              }}
              transition={{
                duration: 2,
                repeat: Infinity,
                ease: "easeInOut"
              }}
            >
              <img 
                src="/speck_icon.png" 
                alt="" 
                className="w-10 h-10 md:w-12 md:h-12 mx-auto mb-2"
              />
            </motion.div>
          </motion.div>

          <motion.div
            className="text-xl md:text-2xl font-bold text-white mb-1"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4 }}
          >
            Quest Completed!
          </motion.div>

          <motion.div
            className="text-2xl md:text-3xl font-bold text-yellow-400"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.6 }}
          >
            +{reward} SPECKS
          </motion.div>
          
          <div className="h-12 mt-4 flex items-center justify-center">
            {showConfirm && (
              <motion.button
                className="px-8 py-2 bg-gradient-to-r from-blue-600 to-purple-600 
                         text-white text-base font-bold rounded-lg shadow-lg
                         hover:from-blue-500 hover:to-purple-500 
                         transition-colors duration-300"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.15 }}
                onClick={handleConfirm}
              >
                Okay
              </motion.button>
            )}
          </div>
        </motion.div>
      )}
    </div>
  );
};

export default QuestCompletionAnimation;