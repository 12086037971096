import axios from 'axios';
import { FETCH_USER_LEADERBOARD_REQUEST, FETCH_USER_LEADERBOARD_SUCCESS, FETCH_USER_LEADERBOARD_FAILURE } from './LeaderboardTypes';
import { predictUserMovements } from '../../utils/LeaderboardUtils';

export const fetchUserLeaderboard = () => async dispatch => {
  dispatch({ type: FETCH_USER_LEADERBOARD_REQUEST });

  try {
    // Fetch the current active leaderboard for the user
    const leaderboardRes = await axios.get('api/v1/leaderboards/current/');
    const leaderboard = leaderboardRes.data;

    // Add validation
    if (!leaderboard || !leaderboard.id) {
      throw new Error('No active leaderboard found');
    }

    // Fetch the records for that leaderboard
    const recordsRes = await axios.get(`api/v1/leaderboards/${leaderboard.id}/records/`);
    const records = recordsRes.data;
    console.log('Received records:', records);

    // Calculate movement predictions on the frontend
    const movements = predictUserMovements(leaderboard, records);
    // Add movement predictions to records
    records.forEach(record => {
      record.movement = movements[record.user.username] || 'stay';
    });

    const payload = { leaderboard, records };
    console.log('Dispatching success action with payload:', payload);

    dispatch({
      type: FETCH_USER_LEADERBOARD_SUCCESS,
      payload: payload
    });
  } catch (err) {
    console.error('Error in fetchUserLeaderboard:', err);
    dispatch({
      type: FETCH_USER_LEADERBOARD_FAILURE,
      payload: err.response?.data?.detail || 'An error occurred while fetching the leaderboard.'
    });
  }
};
