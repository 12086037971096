import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from "axios";
import { submitQuest, fetchSingleQuest, likeQuest, trackQuestView, unlockQuest } from './QuestsActions';
import { Users, Heart, Eye, HelpCircle, BadgeHelp, LockKeyholeOpen } from 'lucide-react';
import { Alert, AlertDescription, AlertTitle } from '../ui/Alert';
import Toast from '../ui/Toast';
import { fetchUserProfile } from '../profile/ProfileActions';
import PrivacyToggleWithTooltip from '../ui/PrivacyToggleWithTooltip';
import QuestCompletionAnimation from './QuestCompletionAnimation';

const PUBLIC_PATH = '/';
const MILLISECONDS_BEFORE_ADDING_VIEW = 5000;
const UNLOCK_COST = 100;  // Keep in sync with QuestInList

export const DEFAULT_FEED_PRIVACY = "ANONYMOUS";

const QuestDetail = ({ 
  quest: propQuest, 
  onHome = false, 
  isAuthenticated, 
  guestAnswer, 
  onGuestSubmit,
  containerClassName = ''
}) => {
  const { questId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const questsState = useSelector(state => state.quests.quests);
  const { user } = useSelector(state => state.auth);
  
  // Core state
  const [userAnswer, setUserAnswer] = useState('');
  const [previousAnswer, setPreviousAnswer] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [submissionPrivacy, setSubmissionPrivacy] = useState(null);
  const [charCount, setCharCount] = useState(0);
  const [currentPrivacy, setCurrentPrivacy] = useState(null);
  
  // UI state
  const [error, setError] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [showTypeInfo, setShowTypeInfo] = useState(false);
  const [isLiking, setIsLiking] = useState(false);
  const [showRewardTooltip, setShowRewardTooltip] = useState(false);
  
  // View tracking
  const [hasTrackedView, setHasTrackedView] = useState(false);
  const viewTimeoutRef = useRef(null);
  
  const { questSlug } = useParams();

  // Unlock
  const [showUnlockModal, setShowUnlockModal] = useState(false);
  const [unlocking, setUnlocking] = useState(false);

  // Completion animation
  const [showAnimation, setShowAnimation] = useState(false);
  const [cardPosition, setCardPosition] = useState(null);
  const questCardRef = useRef(null);

  // Add effect to load user profile
  useEffect(() => {
    if (isAuthenticated && user?.username) {
      dispatch(fetchUserProfile())
        .catch(error => {
          console.error('Failed to load user profile:', error);
        });
    } else {
      console.log("The user is not authenticated -> user profile will not be loaded.")
    }
  }, [isAuthenticated, user?.username, dispatch]);

  // Get quest data either from props or store
  const quest = useMemo(() => (
    propQuest || questsState?.results?.find(q => q.id === parseInt(questId)) || {}
  ), [propQuest, questsState?.results, questId]);

  useEffect(() => {
    // Only update privacy if we don't have a current submission privacy
    if (!currentPrivacy || (!quest?.user_submission && !isEditing)) {
      const getInitialPrivacy = () => {
        if (quest?.user_submission?.feed_privacy) {
          return quest.user_submission.feed_privacy;
        }
        if (user?.feed_privacy) {
          return user.feed_privacy;
        }
        return DEFAULT_FEED_PRIVACY;
      };
  
      const privacySetting = getInitialPrivacy();
      setCurrentPrivacy(privacySetting);
      setSubmissionPrivacy(privacySetting);
      
      console.log('Privacy settings updated:', {
        source: isEditing ? 'editing mode' : 'normal mode',
        privacy: privacySetting,
        currentPrivacy,
        submissionPrivacy: quest?.user_submission?.feed_privacy
      });
    }
  }, [quest?.user_submission?.feed_privacy, user?.feed_privacy]);

  // Set initial answer data
  useEffect(() => {
    if (isAuthenticated && quest.user_submission) {
      setPreviousAnswer(quest.user_submission.text);
      setUserAnswer(quest.user_submission.text);
      setCharCount(quest.user_submission.text?.length || 0);
    } else if (!isAuthenticated && guestAnswer) {
      setUserAnswer(guestAnswer);
      setCharCount(guestAnswer.length || 0);
    }
  }, [quest.user_submission?.text, guestAnswer, isAuthenticated]); // Only run when submission ID or auth status changes

  // Track view after delay
  useEffect(() => {
    if (quest?.id && isAuthenticated && !hasTrackedView) {
      viewTimeoutRef.current = setTimeout(() => {
        dispatch(trackQuestView(quest.id))
          .then(() => setHasTrackedView(true))
          .catch(console.error);
      }, MILLISECONDS_BEFORE_ADDING_VIEW);
  
      return () => {
        if (viewTimeoutRef.current) {
          clearTimeout(viewTimeoutRef.current);
        }
      };
    }
  }, [quest?.id, isAuthenticated, hasTrackedView, dispatch]);

  useEffect(() => {
    if (quest?.user_submission?.feed_privacy) {
      setCurrentPrivacy(quest.user_submission.feed_privacy);
    } else if (user?.feed_privacy) {
      setCurrentPrivacy(user.feed_privacy);
    } else {
      setCurrentPrivacy(DEFAULT_FEED_PRIVACY);
    }
  }, [quest?.user_submission?.feed_privacy, user?.feed_privacy]);

  const handleLikeClick = async () => {
    if (!isAuthenticated) {
      navigate('/login');
      return;
    }
  
    if (isLiking) return;
  
    setIsLiking(true);
    try {
      await dispatch(likeQuest(quest.id));
    } catch (error) {
      setToastMessage('Failed to update like status');
      setShowToast(true);
    } finally {
      setIsLiking(false);
    }
  };

  const handleEditClick = () => {

    const effectivePrivacy = quest.user_submission?.feed_privacy || currentPrivacy;
    
    // Log the privacy value we're working with
    console.log('Current effectivePrivacy:', effectivePrivacy);

    setIsEditing(true);
    setUserAnswer(quest.user_submission.text);
    setPreviousAnswer(quest.user_submission.text);
    setCharCount(quest.user_submission.text?.length || 0);
      
    // Set both privacy states to the effective privacy
    setCurrentPrivacy(effectivePrivacy);
    setSubmissionPrivacy(effectivePrivacy);
  };

  const getQuestTypeDescription = (type) => {
    const descriptions = {
      "question": "You can answer in a couple of sentences or write an essay. Articulate your thoughts clearly and bring your unique perspective to get high rewards.",
      "continue_story": "Build upon a proposed story beginning; go wherever your mind wanders but try to stay consistent with the established plot.",
      "connections": "Identify and explain intriguing links between seemingly unrelated elements. Present your perspective clearly, supporting your discoveries with logical arguments and relevant facts.",
      "permanent": "General questions that have no time limit and can be completed any time. There's no correct answer, simply share your thoughts on the matter."
    };
    return descriptions[type] || "";
  };

  const getQuestTypeIcon = (type) => {
    switch (type) {
      case 'question':
        return '❓';
      case 'continue_story':
        return '📝';
      case 'connections':
        return '🔗';
      case 'permanent':
        return '⏳'
      default:
        return '❓';
    }
  };

  const getQuestTypeName = (type) => {
    switch (type) {
      case 'question':
        return 'Question';
      case 'continue_story':
        return 'Story';
      case 'connections':
        return 'Connections';
      case 'permanent':
        return 'Permanent';
      default:
        return 'Unknown Type';
    }
  };

  const handlePrivacyChange = async (newPrivacy) => {
    console.log('Privacy change requested:', newPrivacy);
    console.log('Previous privacy state:', currentPrivacy);
    
    // Update local state immediately for responsive UI
    setCurrentPrivacy(newPrivacy);
    
    try {
      // First make sure we have a valid submission
      const submissionId = quest?.user_submission?.id;
      console.log('Submission ID:', submissionId); // Debug log
      
      if (!submissionId) {
        // If no submission exists yet, just keep the local state change
        return;
      }
  
      const response = await axios.patch(
        `/api/v1/submissions/${submissionId}/update_privacy/`,
        { feed_privacy: newPrivacy }
      );
      
      if (response.data.feed_privacy) {
        setCurrentPrivacy(response.data.feed_privacy);
        setToastMessage('Privacy setting updated');
        setShowToast(true);
      }
    } catch (error) {
      console.error('Privacy update failed:', error);
      // Revert to previous state on error
      setCurrentPrivacy(quest.user_submission.feed_privacy || user?.feed_privacy || DEFAULT_FEED_PRIVACY);
      setToastMessage('Failed to update privacy setting');
      setShowToast(true);
    }
  };

  // Helper function to check if submission is allowed
  const canSubmit = (quest, submission) => {
    return quest.is_active || (submission && submission.unlocked);
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isAuthenticated) {
      // Check if submission is allowed
      if (!canSubmit(quest, quest.user_submission)) {
        setError('This quest is no longer active and not unlocked.');
        setToastMessage('This quest is no longer active and not unlocked.');
        setShowToast(true);
        return;
      }

      // Store the card's position before animation
      if (questCardRef.current) {
        const rect = questCardRef.current.getBoundingClientRect();
        setCardPosition({
          x: rect.left,
          y: rect.top,
          width: rect.width,
          height: rect.height
        });
      }
  
      try {
        const submittedText = userAnswer;
        const submittedPrivacy = currentPrivacy;

        const response = await dispatch(submitQuest(
          quest.id, 
          userAnswer, 
          submissionPrivacy
        ));
        
        // Ensure we maintain the correct text content
        setUserAnswer(submittedText);
        setPreviousAnswer(submittedText);
        setCharCount(submittedText.length);
        
        // Update both privacy states after successful submission
        setCurrentPrivacy(submittedPrivacy);
        setSubmissionPrivacy(submittedPrivacy);
  
        setIsEditing(false);
        // setToastMessage('Your response has been submitted successfully!');
        // setShowToast(true);
        // Trigger animation instead of toast
        setShowAnimation(true);
        
        if (questSlug) {
          fetchQuestData();
        }
      } catch (error) {
        setError(error.message || 'An error occurred while submitting the quest.');
        setToastMessage('Failed to submit response. Please try again.');
        setShowToast(true);
      }
    } else {
      if (typeof onGuestSubmit === 'function') {
        onGuestSubmit(userAnswer);
      } else {
        localStorage.setItem('guestAnswer', userAnswer);
        localStorage.setItem('guestQuestId', quest.id);
        localStorage.setItem('guestQuestSlug', quest.slug);
        localStorage.setItem('guestPrivacy', currentPrivacy || DEFAULT_FEED_PRIVACY);
        navigate('/signup');
      }
    }
  };

  // Handler for animation completion
  const handleAnimationComplete = () => {
    setShowAnimation(false);
    setIsEditing(false);
    setToastMessage('Your response has been submitted successfully!');
    setShowToast(true);
    
    if (questSlug) {
      fetchQuestData();
    }
  };

  const handleUnlockClick = () => {
    if (!isAuthenticated) {
      navigate('/login');
      return;
    }
    setShowUnlockModal(true);
  };
  
  const handleUnlockConfirm = async () => {
    if (unlocking) return;
    
    setUnlocking(true);
    try {
      await dispatch(unlockQuest(quest.id));
      setToastMessage('Quest unlocked successfully!');
      setShowToast(true);
      setShowUnlockModal(false);
      await fetchQuestData(); // Refresh quest data
    } catch (error) {
      setToastMessage(error.response?.data?.error || 'Failed to unlock quest');
      setShowToast(true);
    } finally {
      setUnlocking(false);
    }
  };
  
  const renderSubmitSection = () => {
    if (!isAuthenticated || !previousAnswer || isEditing) {
      return (
        <div className="mt-6 flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
          <div className="flex items-center">
            <PrivacyToggleWithTooltip
              value={currentPrivacy}
              options={{
                'PUBLIC': 'Public',
                'ANONYMOUS': 'Anonymous',
                'PRIVATE': 'Private'
              }}
              onChange={handlePrivacyChange}
            />
          </div>
          <div className="flex items-center gap-3">
            {/* Unlock Button */}
            {!quest.is_active && !quest.user_submission?.unlocked && !onHome && (
              <div className="relative group">
                <button
                  onClick={handleUnlockClick}
                  disabled={unlocking}
                  className="flex items-center justify-center py-2.5 px-2.5 bg-purple-600/80 
                          hover:bg-purple-700 rounded-lg text-white transition-all 
                          duration-300 shadow-md hover:shadow-lg transform hover:scale-105
                          disabled:opacity-50 disabled:transform-none disabled:shadow-none"
                  aria-label="Unlock quest"
                >
                  <LockKeyholeOpen className="w-5 h-5" />
                </button>
                <div className="absolute bottom-full mb-4 left-1/2 transform -translate-x-1/2 hidden group-hover:block whitespace-nowrap">
                  <div className="bg-indigo-950 text-white text-sm py-2 px-4 rounded-lg shadow-lg whitespace-nowrap border border-indigo-700">
                    Unlock quest ({UNLOCK_COST}
                    <img 
                      src="/speck_small.png"
                      alt="SPECKS" 
                      className="w-4 h-4 mx-0.5 inline-block brightness-100 opacity-100"
                    />
                    )
                  </div>
                </div>
              </div>
            )}
            
            {/* Submit Button */}
            <div className="relative group w-full sm:w-auto">
              <button
                onClick={handleSubmit}
                className={`
                  w-full sm:w-auto py-3 px-8 rounded-lg font-bold text-lg
                  bg-gradient-to-r from-purple-700 to-purple-800
                  hover:from-purple-800 hover:to-purple-900
                  text-white transition duration-300 transform hover:scale-105
                  disabled:opacity-50 disabled:cursor-not-allowed
                  shadow-lg
                `}
                disabled={!canSubmit(quest, quest.user_submission)}
              >
                {isAuthenticated && previousAnswer ? 'Update Answer' : 'Submit Answer'}
              </button>
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  const renderStatsGrid = () => (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-4 md:gap-8 p-4 bg-gray-800 bg-opacity-50 rounded-lg">
    <div className="flex items-center justify-center space-x-2">
      <img 
        src={`${PUBLIC_PATH}speck_small.png`}
        alt="Reward" 
        className="w-6 md:w-8 h-6 md:h-8 brightness-100 opacity-100"
      />
      {quest.submission_stats?.value_reward > 0 ? (
        // Show the calculated total when we have a value reward > 0
        <span className="text-sm md:text-base text-gray-300">
          {quest.base_reward + quest.submission_stats.value_reward}
        </span>
      ) : (
        // Otherwise show base reward + question mark tooltip
        <div className="flex items-center">
          <span className="text-sm md:text-base text-gray-300">{quest.base_reward}</span>
          <span className="text-gray-300 mx-0.5">+</span>
          <div className="relative inline-block"
              onMouseEnter={() => setShowRewardTooltip(true)}
              onMouseLeave={() => setShowRewardTooltip(false)}
              onTouchStart={() => setShowRewardTooltip(true)}
              onTouchEnd={() => setShowRewardTooltip(false)}>
            <BadgeHelp 
              size={16} 
              className="text-yellow-400 cursor-help" 
            />
            {showRewardTooltip && (
              <div className="absolute z-10 w-72 p-2 bg-indigo-950 rounded-lg shadow-xl border border-indigo-700 
                            top-full mt-3 left-1/2 -translate-x-1/2">
                <div className="absolute -top-2 left-1/2 -translate-x-1/2 w-4 h-4 
                              bg-indigo-950 border-l border-t border-indigo-700 
                              transform rotate-45"></div>
                <p className="text-gray-200 text-sm pt-2 text-justify">
                  {quest.quest_type === 'PERMANENT' 
                    ? "Additional reward will be calculated periodically based on the uniqueness and quality of your response."
                    : "Additional reward will be calculated based on the uniqueness and quality of your response when the quest ends."}
                </p>
              </div>
            )}
          </div>
        </div>
      )}
      </div>
      <div className="flex items-center justify-center space-x-2">
        <Users size={16} className="text-gray-400 md:w-5 md:h-5" />
        <span className="text-sm text-center md:text-base text-gray-300">{quest.completions} completed</span>
      </div>
      <div className="flex items-center justify-center space-x-2 cursor-pointer" onClick={handleLikeClick}>
        <Heart 
          size={16}
          className={`md:w-5 md:h-5 ${quest.is_liked ? 'text-red-500 fill-red-500' : 'text-gray-400'} 
            transition-colors duration-300 ${isAuthenticated ? 'cursor-pointer hover:scale-110' : 'cursor-not-allowed'}`}
        />
        <span className="text-sm md:text-base text-gray-300">{quest.likes_count || 0}</span>
      </div>
      <div className="flex items-center justify-center space-x-2">
        <Eye size={16} className="text-gray-400 md:w-5 md:h-5" />
        <span className="text-sm md:text-base text-gray-300">{quest.views_count || 0}</span>
      </div>
    </div>
  );

  const fetchQuestData = useCallback(() => {
    if (!questSlug && !quest?.id) {
      return Promise.reject(new Error('No quest identifier available'));
    }
  
    return dispatch(fetchSingleQuest(questSlug || quest.id))
      .then(data => {
        if (data.user_submission && !isEditing) {
          setPreviousAnswer(data.user_submission.text);
          setUserAnswer(data.user_submission.text);
          setCharCount(data.user_submission.text?.length || 0);
        }
        
        // Properly handle privacy setting
        const submissionPrivacy = data.user_submission.feed_privacy;
        if (submissionPrivacy !== null && submissionPrivacy !== undefined) {
          setCurrentPrivacy(submissionPrivacy);
        }
        return data;
      })
      .catch(err => {
        setError(err.message || 'An error occurred while fetching the quest.');
        throw err;
      });
  }, [dispatch, questSlug, quest?.id, isEditing]);

  useEffect(() => {
    if (!propQuest && questSlug) {
      fetchQuestData();
    }
  }, [propQuest, questSlug, fetchQuestData]);

  const questContent = (
    <div className={`w-full flex justify-center font-sans ${containerClassName}`}>
      <div className={`relative w-full ${onHome ? 'max-w-4xl' : 'max-w-6xl'}`}>
        <div className="absolute inset-0">
            {showAnimation && (
              <QuestCompletionAnimation
              isVisible={showAnimation}
              onComplete={handleAnimationComplete}
              cardPosition={cardPosition}
              reward={quest.base_reward}
              questTitle={quest.quest_title}
              questText={quest.quest_text}
              userAnswer={userAnswer}
              baseReward={quest.base_reward}
              completions={quest.completions}
              likesCount={quest.likes_count}
              viewsCount={quest.views_count}
            />
            )}
          </div>
        {/* Badges container - positioned absolutely on the border */}
        <div className="absolute -top-3 right-5 flex items-center gap-2 z-20">
          {/* Quest Type Badge - now first */}
          {quest.quest_type && (
            <div 
              className="relative group"
              onMouseEnter={() => setShowTypeInfo(true)}
              onMouseLeave={() => setShowTypeInfo(false)}
            >
              <div className="flex items-center bg-indigo-900 hover:bg-indigo-800 rounded-full px-3 sm:px-4 py-1.5 sm:py-2 text-sm sm:text-base cursor-help shadow-lg transition-all duration-200">
                <span className="mr-1.5">{getQuestTypeIcon(quest.quest_type)}</span>
                <span className="text-gray-200 text-xs sm:text-base">
                  {getQuestTypeName(quest.quest_type)}
                </span>
                <HelpCircle className="w-4 h-4 ml-1 text-gray-300" />
              </div>
              
            {/* Quest Type Badge tooltip */}
            {showTypeInfo && (
              <div className="absolute z-10 w-52 sm:w-72 p-2 sm:p-4 bg-indigo-950 rounded-lg shadow-xl border border-indigo-700 
                            right-0 top-full mt-2">
                <div className="absolute -top-2 right-8 w-4 h-4 bg-indigo-950 border-l border-t border-indigo-700 transform rotate-45"></div>
                <p className="text-gray-200 text-xs sm:text-sm leading-tight">
                  {getQuestTypeDescription(quest.quest_type)}
                </p>
              </div>
            )}
            </div>
          )}

          {/* Base Reward Badge - now second */}
          <div className="relative group flex justify-center"> 
            <div className="flex items-center bg-gray-800 hover:bg-indigo-900 px-3 py-1.5 rounded-full shadow-lg transition-all duration-200">
              <img 
                src={`${PUBLIC_PATH}speck_small.png`}
                alt="Specks" 
                className="w-5 h-5 sm:w-6 sm:h-6 mr-1.5 sm:mr-2"
              />
              <span className="text-xs sm:text-base font-semibold text-gray-300">
                {quest.base_reward}
              </span>
            </div>
            
            {/* Base Reward Tooltip - adjusted pointer position */}
            <div className="absolute hidden group-hover:block -right-4 top-full mt-2">
              <div className="bg-indigo-950 text-white text-sm py-2 px-4 rounded-lg shadow-lg whitespace-nowrap border border-indigo-700">
                Base Reward
              </div>
              <div className="absolute -top-2 right-8 w-4 h-4 bg-indigo-950 border-l border-t border-indigo-700 transform rotate-45"></div>
            </div>
          </div>
        </div>
  
        {/* Subtle border container */}
        <div className="absolute -inset-[1px] overflow-hidden rounded-xl">
          <div 
            className="absolute inset-0 rounded-xl"
            style={{
              background: `
                linear-gradient(
                  135deg,
                  rgba(30, 64, 175, 0.1),
                  rgba(67, 56, 202, 0.2),
                  rgba(109, 40, 217, 0.3),
                  rgba(147, 51, 234, 0.2),
                  rgba(30, 64, 175, 0.1)
                )
              `,
              boxShadow: `
                inset 0 0 12px rgba(67, 56, 202, 0.1),
                0 0 4px rgba(109, 40, 217, 0.1)
              `
            }}
          />
        </div>
  
        {/* Main content container */}
        <div 
          ref={questCardRef}
          className={`relative w-full ${onHome ? 'max-w-4xl' : 'max-w-6xl'} min-h-[600px] bg-gray-900 p-8 rounded-xl z-10`}
        >
          {error && (
            <Alert variant="destructive">
              <AlertTitle>Error</AlertTitle>
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}
          
          {/* Title section */}
          <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-6 pr-4 sm:pr-8 break-words leading-tight">
            {onHome ? (
              <Link 
                to={`/quest/${quest.slug}`}
                className="bg-gradient-to-r from-indigo-600 to-purple-700 bg-clip-text text-transparent hover:opacity-80 transition-opacity pl-6"
              >
                {quest.quest_title}
              </Link>
            ) : (
              <span className="bg-gradient-to-r from-indigo-600 to-purple-700 bg-clip-text text-transparent pl-6">
                {quest.quest_title}
              </span>
            )}
          </h2>
  
          {/* Quest text */}
          <div className="mb-8">
            {quest.quest_text.split('\n').filter(Boolean).map((paragraph, index) => (
              <p 
                key={index} 
                className={`
                  text-base sm:text-lg text-gray-300
                  text-justify
                  indent-6
                  ${index !== 0 ? 'mt-4' : ''}
                  w-full
                  px-1 sm:px-2
                `}
              >
                {paragraph}
              </p>
            ))}
          </div>

          {/* Textarea section */}
          <div className="relative mb-8">
            <textarea
              className={`w-full p-4 sm:p-6 bg-gray-800 text-white rounded-lg resize-none 
                        min-h-[200px] sm:min-h-[320px] border border-gray-700 
                        focus:border-purple-500 focus:ring focus:ring-purple-500 focus:ring-opacity-50
                        text-sm sm:text-base ${
                isAuthenticated && previousAnswer && !isEditing ? 'filter blur-[2px]' : ''
              }`}
              value={userAnswer}
              onChange={e => {
                const newValue = e.target.value;
                if (newValue.length <= quest.max_chars) {
                  setUserAnswer(newValue);
                  setCharCount(newValue.length);
                }
              }}
              maxLength={quest.max_chars}
              placeholder="Enter your answer here..."
              disabled={((isAuthenticated && previousAnswer && !isEditing) || 
                (!canSubmit(quest, quest.user_submission) && isEditing))}
              readOnly={!canSubmit(quest, quest.user_submission) && isEditing}
            ></textarea>

            {/* Character count display - adjusted for mobile */}
            <div className="absolute bottom-2 right-2 sm:right-4 text-xs sm:text-sm text-gray-400 bg-gray-800/50 px-2 py-1 rounded">
              <span className={charCount === quest.max_chars ? 'text-yellow-400' : ''}>
                {charCount}
              </span>
              <span> / {quest.max_chars}</span>
            </div>

            {/* Overlay for submitted answers */}
            {isAuthenticated && previousAnswer && !isEditing && (
              <div className="absolute inset-0 flex flex-col items-center justify-center bg-gray-800 bg-opacity-70 rounded-lg px-4">
                <p className="text-center text-white mb-4 text-sm sm:text-base">
                  {quest.is_active 
                    ? "You've already submitted your response to this quest. Would you like to modify it?"
                    : "This quest has ended. Would you like to view your submitted response?"}
                </p>
                <button
                  onClick={handleEditClick}
                  className={`font-bold py-2 px-6 rounded-lg transition duration-300 text-sm sm:text-base ${
                    quest.is_active
                      ? "bg-purple-800 hover:bg-purple-700 transition duration-300 transform hover:scale-105 text-white"
                      : "bg-purple-800 hover:bg-purple-700 transition duration-300 transform hover:scale-105 text-white"
                  }`}
                >
                  {quest.is_active ? "Edit Response" : "View Response"}
                </button>
              </div>
            )}

            {/* Close button for non-active quests */}
            {isEditing && !quest.is_active && (
              <div className="mt-6 flex justify-center">
                <button
                  onClick={() => setIsEditing(false)}
                  className="bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-6 rounded-lg transition duration-300 text-sm sm:text-base"
                >
                  Close
                </button>
              </div>
            )}
          </div>

        {/* Stats grid */}
        {renderStatsGrid()}
          
        {(!isAuthenticated || !previousAnswer || isEditing) && (
          renderSubmitSection() 
        )}

        {showToast && (
          <Toast 
            message={toastMessage} 
            type={toastMessage.includes('Failed') ? 'error' : 'success'}
            onClose={() => setShowToast(false)} 
          />
        )}
      </div>

      {/* UnlockModal */}
      {showUnlockModal && (
      <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm z-50 flex items-center justify-center p-4">
        <div className="bg-gray-800 rounded-lg max-w-md w-full p-6">
          <h3 className="text-xl font-semibold mb-4 text-white">
            {user?.specks_balance >= UNLOCK_COST ? "Unlock Quest?" : "Insufficient SPECKS Balance"}
          </h3>
          
          <p className="text-gray-300 mb-6">
            {user?.specks_balance >= UNLOCK_COST ? (
              `Unlock this quest to update / submit your response any time. It costs ${UNLOCK_COST} SPECKS. Proceed?`
            ) : (
              `You need ${UNLOCK_COST} SPECKS to unlock this quest. Complete any active quest to earn SPECKS!`
            )}
          </p>

          <div className="flex justify-end space-x-4">
            {user?.specks_balance >= UNLOCK_COST ? (
              <>
                <button
                  onClick={() => setShowUnlockModal(false)}
                  disabled={unlocking}
                  className="px-4 py-2 text-gray-300 border border-gray-600 hover:border-gray-500 
                            hover:text-white rounded-lg font-medium transition-all duration-200 
                            disabled:opacity-50 bg-gray-800 hover:bg-gray-700"
                >
                  Cancel
                </button>
                <button
                  onClick={handleUnlockConfirm}
                  disabled={unlocking}
                  className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded 
                            transition-colors duration-200 disabled:opacity-50"
                >
                  {unlocking ? 'Unlocking...' : 'Unlock'}
                </button>
              </>
            ) : (
              <button
                onClick={() => setShowUnlockModal(false)}
                className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded 
                          transition-colors duration-200"
              >
                Okay
              </button>
            )}
          </div>
        </div>
      </div>
      
    )}
    </div>
    </div>
  
  );

  return questContent;
};

export default QuestDetail;